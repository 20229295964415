import { Config } from '@chegg-wt/core/config'
import { Resource } from '@chegg-wt/core/types/core'

const resources: Resource[] = [
  {
    header: 'MLA Format: Everything You Need to Know and More',
    summary:
      'Filled with a wide variety of examples and visuals, our Citation Machine® MLA guide will help you master the citation process. Learn how to cite websites, books, journal articles, magazines, newspapers, films, social media, and more!',
    url: `${Config.baseUrl}/mla/cite-a-website`,
    seoLinks: [
      {
        children: 'MLA Citation Generator',
        href: `${Config.baseUrl}/mla`,
      },
      {
        children: 'Website',
        href: `${Config.baseUrl}/mla/cite-a-website`,
      },
      {
        children: 'Books',
        href: `${Config.baseUrl}/mla/cite-a-book`,
      },
      {
        children: 'Journal Articles',
        href: `${Config.baseUrl}/mla/cite-a-journal`,
      },
      {
        children: 'YouTube',
        href: `${Config.baseUrl}/mla/cite-a-film`,
      },
      {
        children: 'Images',
        href: `${Config.baseUrl}/mla/cite-a-image`,
      },
      {
        children: 'Movies',
        href: `${Config.baseUrl}/mla/cite-a-film`,
      },
      {
        children: 'Interview',
        href: `${Config.baseUrl}/mla/cite-a-interview`,
      },
      {
        children: 'PDFs',
        href: `${Config.baseUrl}/mla/cite-a-digital`,
      },
    ],
    dataTestId: 'mla-guide-link',
  },
  {
    header: 'Comprehensive Guide to APA Format',
    summary:
      'Our Citation Machine® APA guide is a one-stop shop for learning how to cite in APA format. Read up on what APA is, or use our citing tools and APA examples to create citations for websites, books, journals, and more!',
    url: `${Config.baseUrl}/apa/cite-a-book`,
    seoLinks: [
      {
        children: 'APA Citation Generator',
        href: `${Config.baseUrl}/apa`,
      },
      {
        children: 'Website',
        href: `${Config.baseUrl}/apa/cite-a-website`,
      },
      {
        children: 'Books',
        href: `${Config.baseUrl}/apa/cite-a-book`,
      },
      {
        children: 'Journal Articles',
        href: `${Config.baseUrl}/apa/cite-a-journal`,
      },
      {
        children: 'YouTube',
        href: `${Config.baseUrl}/apa/cite-a-film`,
      },
      {
        children: 'Images',
        href: `${Config.baseUrl}/apa/cite-a-image`,
      },
      {
        children: 'Movies',
        href: `${Config.baseUrl}/apa/cite-a-film`,
      },
      {
        children: 'Interview',
        href: `${Config.baseUrl}/apa/cite-a-interview`,
      },
      {
        children: 'PDFs',
        href: `${Config.baseUrl}/apa/cite-a-digital`,
      },
    ],
    dataTestId: 'apa-guide-link',
  },
  {
    header: 'Everything You Need to Know About Chicago Style',
    summary:
      'Creating citations in Chicago style has never been easier thanks to our extensive Citation Machine® Chicago style guide and tools. Learn about footnotes, endnotes, and everything in between, or easily create citations for websites, books, journal articles, and more!',
    url: `${Config.baseUrl}/chicago`,
    seoLinks: [
      {
        children: 'Chicago Citation Generator',
        href: `${Config.baseUrl}/chicago`,
      },
      {
        children: 'Website',
        href: `${Config.baseUrl}/chicago/cite-a-website`,
      },
      {
        children: 'Books',
        href: `${Config.baseUrl}/chicago/cite-a-book`,
      },
      {
        children: 'Journal Articles',
        href: `${Config.baseUrl}/chicago/cite-a-journal`,
      },
      {
        children: 'YouTube',
        href: `${Config.baseUrl}/chicago/cite-a-film`,
      },
      {
        children: 'Images',
        href: `${Config.baseUrl}/chicago/cite-a-image`,
      },
      {
        children: 'Movies',
        href: `${Config.baseUrl}/chicago/cite-a-film`,
      },
      {
        children: 'Interview',
        href: `${Config.baseUrl}/chicago/cite-a-interview`,
      },
      {
        children: 'PDFs',
        href: `${Config.baseUrl}/chicago/cite-a-digital`,
      },
    ],
    dataTestId: 'chi-guide-link',
  },
  {
    header: 'Citation Machine®’s Ultimate Writing Guides',
    summary:
      'Whether you’re a student, writer, foreign language learner, or simply looking to brush up on your grammar skills, our comprehensive grammar guides provide an extensive overview on over 50 grammar-related topics. Confused about reflexive verbs, demonstrative adjectives, or conjunctive adverbs? Look no further! Learn about these grammar topics and many, many more in our thorough and easy to understand reference guides!',
    url: `${Config.baseUrl}/resources/grammar-guides/`,
    seoLinks: [
      {
        children: 'Citing Sources Guide',
        href: `${Config.baseUrl}/resources/cite-sources/`,
      },
      {
        children: 'Grammar Guide',
        href: `${Config.baseUrl}/resources/grammar-guides/`,
      },
      {
        children: 'Plagiarism Guide',
        href: `${Config.baseUrl}/resources/plagiarism/`,
      },
      {
        children: 'Writing Tips',
        href: `${Config.baseUrl}/resources/writing-resources/`,
      },
    ],
    dataTestId: 'grammar-guides-link',
  },
  {
    header: 'Student Blog',
    summary:
      'Stay up to date! Get research tips and citation information or just enjoy some fun posts from our student blog.',
    url: `${Config.baseUrl}/resources`,
    dataTestId: 'blog-link',
  },
]

export default resources

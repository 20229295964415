import React from 'react'
import { getLvpLayout } from '@chegg-wt/core/components/Layouts/LargeVideoPlayerLayout'
import { NextPageWithLayout } from '@chegg-wt/core/types/layout'
import Home, { HomeTop } from '@chegg-wt/core/components/Layouts/Home'
import SEOTitle from '@chegg-wt/core/components/SEOTitle'
import { useOnUnloadTimeOnPage } from '@chegg/time-on-page'
import { cards, plusTabs, oldPricingTableData, newPricingTableData } from '../copy/Home'
import resources from '../copy/resources'

const resourceTitle = 'Citation Machine® Guides & Resources'
const HomePage: NextPageWithLayout = () => {
  useOnUnloadTimeOnPage()
  return (
    <>
      <SEOTitle title="Citation Machine®: Format & Generate - APA, MLA, & Chicago" />
      <Home
        copy={{
          oldPricingTableData,
          newPricingTableData,
          plusTabs,
          resources,
          resourceTitle,
        }}
      />
    </>
  )
}

HomePage.getPageLayout = (page) => getLvpLayout(page, () => <HomeTop cards={cards} />)

export default HomePage
